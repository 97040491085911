.form-group {
    margin-bottom: 0.4rem;

}
label {
    display: inline-block;
    margin-bottom: 0.1rem;
}

body{
    background-color: #293D52 !important;
}

.card-shaded{
    box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
}
.login-btn{
    background-color: #26B99A;
    padding: 0.5rem;
    border-radius: 10px;
    color: white !important;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 5px 10px rgb(16 30 54 / 25%);
}
.login-container{
    right: 10px;
    bottom: 10px;
}
.branded-container{

    right: 10px;
    top: 10px;
}

@media (min-width: 0px) {
    .login-spinner{
        width: 10px;
      height: 10px;
    }

    .registration-wrapper{ width: 100%;


    }
    .login-container{
        display: none !important;
    }
}
.login-spinner{
    width: 17px !important;
    height: 17px !important;
}
@media (min-width: 576px) {


    .registration-wrapper{ width: 80%;
        margin-top: 45px;
        .chicken-app-logo-title{
            margin-top: 55px;
        }
    }
    .login-container{
        display: block !important;
    }
}
@media (min-width: 768px) { .registration-wrapper{width: 55%;    }}




  /*  // Medium devices (tablets, 768px and up)


    // Large devices (desktops, 992px and up)


    // Extra large devices (large desktops, 1200px and up)

    width: 50%;*/


//

.alert-container{


position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100;
    box-shadow: 0 5px 10px rgb(16 30 54 / 25%);

    justify-content: center;
    align-content: center;
    padding: 1rem;
    border-radius: 0.5rem;
    top: 0;
    left: 0;



    transform: translate(calc(50vw - 50%), calc(50vh - 50%));



}

.separator-page{
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;




}
.blurry{
    filter: blur(2px);
}
.show-separator{
    z-index: 4;
    visibility: visible;
}
.registration-server-errors{

    position: fixed;
  bottom: 20px;
    left: 10px;
    z-index: 50;

}

.credentials-card{
    box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
    border-radius: 15px;
}

.credentials-card .card-header{

    border-radius: 15px;
}
.reset-password-wrapper{

    align-content: center;
    height: fit-content;

}