.freeTrialToast {
    
    &__head {
        font-size: 1.15rem;
        font-weight: 500;
    }

    &__body {
        &__text {
            font-size: 1rem;
            font-weight: 400;
        }

        &__btn {
            display: flex;
            justify-content: flex-end;
        }
    }
}