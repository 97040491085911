/*


// CoreUI and its default variables


*/

$info: #26B99A;
$primary:       #26B99A ;


@font-face {
  font-family: 'cairoChickenApp';
  src:  url('../../src/assets/Cairo/static/Cairo-Medium.ttf') format('truetype')

}
*{
  font-family: 'cairoChickenApp', serif !important;
}

.btn.btn-primary{
  background: #26B99A
}
.chicken-app-btn:disabled{
  background: #2a977b !important;
}
.chicken-app-btn{
  //background: #26B99A !important;
  background:  #26B99A;
  color: white !important;
  padding: 4px 10px;
  border-radius: 10px;
  border: none;
  text-decoration: none !important;
  line-height: 10px !important;
}
.chicken-app-btn:focus, .chicken-app-btn.focus {
  color: #fff;
  background-color: #1f9980;
  border-color: #1d8f77;
  box-shadow: 0 0 0 0.2rem rgb(71 196 169 / 50%);
  height: fit-content;
}
.chicken-app-btn:hover {
  color: #fff;
  background-color: #1f9980;
  border-color: #1d8f77;
}



.btn{
  border-radius: 10px !important;
}

[dir="rtl"] .fa-lg {
  margin-left:10px !important;
  margin-right:0px !important;
}
[dir="rtl"]  .daily-data-btn {
  position: fixed;
  z-index: 1;
  bottom: 1133px;
  left: 30px !important;
  border: 4px solid white;
  padding: 10px;
  border-radius: 20px;
}

.border-warning-chicken-app{
  border-left: 3px solid #f9b115 !important;
}

.border-info-chicken-app{
  border-left: 3px solid #157cf9 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cecece !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}

.modal-content, {
  border: none !important;
  border-radius: 10px !important;
}
.modal-header{
  border-radius: 10px 10px  0px 0px !important;
}
.btn-secondary{
  background-color: #ced2d85c !important;
}
.p-l-0{
  padding-left: 0 !important;
}
label{

  line-height: 25px;
}
.react-multi-carousel-list.container{
  padding: 0 !important;
}

.summary-card{
  .summary-card-name{

    font-size: 1rem;
    margin-bottom: 10px;
    };
  .summary-card-value{
    font-size: 2rem;
    text-align: center;
  }
}


.container{
  max-width: 1200px !important;
}

.bg-danger-light{
  background-color: rgba(229, 83, 83, 0.44);
}

.farm-column{
  border: 1px solid black ;

}
.w-fit{
  width: fit-content !important;
}
.z-index-100{
  z-index: 100 !important;
}
.delete-alert{
  bottom:25px ;
  right: 50px;
}
.daily-data-submit-btn-spinner{
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}
.bg-warning-light{
  background-color: rgba(255, 165, 0, 0.09);
}



.pulsating-circle {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 12px;
  height: 12px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-right: -100%;
    margin-top: -100%;
    border-radius: 45px;
    direction: rtl;
    background-color: #efb40a;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }




  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #efb40a;
    border-radius: 15px;
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.left-15{
  left: 15px;
}
.right-15{
  right: 15px;
}

.chart-wrapper {
  position: relative;
  padding-bottom: 40%;
}

.chart-inner {
  position: absolute;
  width: 100%; height: 100%;
}
.chicken-app-map-container{
  width:100%;
  margin: 0 auto;
}
.info-other-type-batch{
  border:1px solid black;
  border-radius: 50px;
  box-sizing: content-box;
  padding: 5px;

  width: 10px;
  height: 10px;




}
.MuiStepConnector-alternativeLabel {
  top: 12px;
  position: absolute;
  [dir = rtl] &{
    left: calc(50% + 20px);
    right: calc(-50% + 20px);
  }
  [dir = ltr] &{
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
  }
}

.farm-car-col {

  [dir = ltr] &{
    border-left: 1px solid #d9d9d9;
  }
  [dir = rtl] &{
    border-right: 1px solid #d9d9d9;
  }
}


.colorize{
  background-color: rgba(212, 100, 100, 0.31);
}
.danger-daily-data-card{
   border-right: 4px solid rgba(255, 0, 0, 0.76) !important;
}
.danger-daily-data-card header{
  background-color: rgba(255, 0, 0, 0.14);
}
.iconContainer {
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  [lang='en'] &{
    right: 10px;
    height: 100%;
    width: fit-content;
    position: absolute;
  }
  [dir='rtl']  &{
    position: absolute;
    left: 10px !important;
    right: unset !important;
    height: 100%;
    width: fit-content;
  }
}
.react-datepicker__input-container  .c-icon{
  margin: 0 5px;

}
.react-datepicker__input-container .d-flex.align-items-center{
  [dir='rtl'] &{
    flex-direction: row-reverse !important;
  }
}
[dir='rtl'] .d-flex.align-items-center .mr-3{
  margin-right: 0px !important;
}


.mobile-login-title{
  @media (min-width: 0px) {
    display: block;
  }
  @media (min-width: 580px) {
    display: none;
  }

}
@media (min-width: 580px) {
  .chicken-app-logo{
    position: absolute;
    background: #ffffff;
    padding: 10px;
    border-radius: 100%;
    top: -15%;
  }
  .chicken-app-logo-title{
    margin-top: 70px;
    width: 175px;
  }
}
@media (max-width: 580px) {
  .login-page{
    .container.vh-100{
      padding: 0;
      .registration-wrapper{
        height: 100%;
        background-color: red;
        padding: 0;
        input{
          padding: 20px !important;
        }
        button{
          margin-top: 30px;
          padding: 12px !important;
        }
        .col{
          padding: 0!important;
          margin: 0 !important;
          .card{
            border-radius: 0!important;
            border:none;
            margin: 0;
            height: 100%;
            padding: 15px;
            .card-header{
              margin-top: 100px;
              border-bottom: 0 !important;
              .chicken-app-logo{
                width: 100px;
              }

            }
          }

        }
        .text-gray-100.text-center.d-block.w-100{
          display: none !important;
        }
      }
    }
  }
  .container.vh-100{
    select{
      height: 45px;
    }
    input{
      padding: 20px !important;
    }
    button, {
      margin-top: 30px;
      padding: 12px !important;
    }
    padding: 0;
    .registration-wrapper{

      padding: 0;
      margin: 0!important;
      height: 100%;
      .col.m-2{
        margin: 0 !important;
        padding: 0 !important;
        .card{
          border-radius: 0!important;
          border:none;
          margin: 0;
          height: 100%;
          padding: 0 15px;
          .card-header{
            margin-top: 20px;
            border-bottom: 0 !important;
            .chicken-app-logo{
              width: 100px;
            }
            .chicken-app-logo-title{
              width: 200px;
              margin-top: 10px;
            }
          }
        }

      }
      h1{
        font-size:20px ;
        text-align: center;
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        margin: 25px 0;

      }
    }
    .mobile-login-from-registration{
      padding: 0 20px;
      display: flex;
      a{
        text-decoration: underline;
      }
    }
  }

}
@media (max-width: 2000px) {

  .chicken-app-logo-title{
    width: 200px;
    margin-top: 40px;
  }
  h1{
    display: none;
  }

    .otp-card{
      height: 100vh;

      background:linear-gradient(0deg, rgba(24, 34, 51, 0.95), rgba(24, 34, 51, 0.95)), url('../../src/assets/smartfarm.png') !important;

      color: white !important;
      border: 0 !important;

    }
  .otp-card ::after{
    background: rgba(24, 34, 51, 0.39) !important;
  }
    .opt-row{
      display: flex;
      justify-content: center;
      gap: 10px;
      .otp-field{
        height: 50px !important;
        width: 50px !important;
        border-radius: 15px !important;
        justify-content: center !important;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;

      }
    }
  .remove-btn{
    color: #e4e4e4 !important;
  }
}

.form-group {

  @media (max-width: 1000px) {
    margin-bottom: 0.1rem !important;

  }
  @media (max-width: 425px) {
    margin-bottom: 0.5rem !important;

  }
}
 .daily-data-btn{
 [dir="rtl"] &{
   position: fixed;
   z-index: 1000000;
   bottom: 30px;
   right: 10px;
   border: 4px solid white;
   padding: 10px;
   border-radius: 20px;
 }
 }

  .daily-data-btn{
  background-color: #1f9980 !important;
}
@media only screen and (max-width: 600px) {
  .collapsible-container{
    display: none !important;
  }
  .mobile-collapsable-container{
    display: block !important;
  }
  .daily-data-btn{
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 30px;
    border: 2px solid white;
    padding: 13px;
    border-radius: 20px;
    width: fit-content;
  }
  .mobile-mt-4{
    margin-top: 1.2rem;
  }
  //do not delete this rule
  .c-datatable-items-per-page {

  margin-top: 10px;
  .mfe-2{
    width: 100%;
  }
  }

  .opt-row{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .otp-card{
    color: #293D52 !important;
    background: #fafafa !important;
    height: 100vh;
    border-radius: 0 !important;
  }
  .chicken-app-log-opt{
    mix-blend-mode: difference;
    opacity: 0.8;
  }
  .remove-btn{
    color: #293D52 !important;
  }
  .otp-field{
    height: 50px !important;
    width: 50px !important;
    border-radius: 15px !important;
    justify-content: center !important;
    text-transform: uppercase;
  }
  .login-title{

    font-size: 25px;
    margin-bottom: 40px;
  }
  .daily-data-btn{
    padding: 10px !important;
  }
}

html:not([dir=rtl]) .c-sidebar-nav-dropdown-toggle::after {
  margin-left: auto;
  transform: rotate(
                  180deg
  );
}

html:not([dir=rtl]) .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(270deg) !important;
}

.std-breed-filter{
  top: 20px;
  max-height: 250px !important;
  overflow-y: scroll !important;
  border-radius:10px ;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #eaeaea !important;
  opacity: 1;
}
.std-add-breed-btn-col{
  width: 40% !important;
}

@media (min-width: 992px){
.c-sidebar.c-sidebar-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1029 !important;
}
}
@media only screen and (max-width: 425px) {
  .daily-data-btn {
    position: fixed;
    z-index: 1030 !important;
    bottom: 30px;
    right: 13px;
    border: 4px solid white;
    padding: 10px;
    border-radius: 20px;
  }
  .confirm-reset-password-sent{
    height: 100vh !important;
  }
  .chicken-app-btn{
    padding: 13px 10px !important;
  }
  td .badge.badge-danger {
    font-size: 12px ;
    padding: 5px;
  }
  td .badge.badge-success {
    font-size: 12px ;
    padding: 5px;
  }
  td .badge.badge-warning {
    font-size: 12px ;
    padding: 5px;
  }
  .card-header .chicken-app-btn{
    padding: 5px 10px !important;
  }
  //for full page no data screen
  .fullpage-nodata {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
        margin-top: 50px;
        width: 15rem !important;
        height: 8rem !important;
        fill: #c5c5c5;
      path {
        fill: #c5c5c5;
      }
      polyline {
        fill: #c5c5c5;
      }
    }
    span {
      color: #c5c5c5;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 2000px) {
  .confirm-reset-password-sent{
    height: fit-content ;
  }
  .chicken-app-btn{
    padding: 5px 10px ;
  }
  //for full page no data screen
  .fullpage-nodata {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      margin-top: 50px;
      width: 35rem;
      height: 8rem;
      fill: #c5c5c5;
      path {
        fill: #c5c5c5;
      }
      polyline {
        fill: #c5c5c5;
      }
    }
    span {
      color: #c5c5c5;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
.position-relative.table-responsive input{
  background-color: unset;
}
.lang-toggle .c-switch-slider{
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#26b99a), color-stop(34%,#2ccbaa), color-stop(35%,#2dcfad), color-stop(100%,#22a287)) !important;
  &:after{
    color: white !important;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(158, 158, 158, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
}
[dir='rtl']  .sidebar-icon{
  margin-right: 5px !important;
  margin-left: 20px !important;
}
[lang='en'] .sidebar-icon{
  margin-right: 20px ;
  margin-left: 5px ;
}
.last-sidebar-element{
  margin-top: auto;
}

/*
.c-sidebar-minimized .c-sidebar-nav > .last-sidebar-element{
  bottom: 50px;
}*/
.c-header.c-header-fixed{
  z-index: 1028 !important;
}
.modal-dialog{
  color: #3c4b64;
}
.c-main{
  padding-top: 1rem !important;
}
.highcharts-container {
  width:100% !important;
  height:100% !important;
}
.btn.btn-warning{
  color: white;
}
.custom-select {
  [dir=rtl] & {
    background-position: 10px !important;
  }
}
 .was-validated .custom-select:invalid, .custom-select.is-invalid {
   [dir=rtl] &{
     background-position: 10px !important;
   }
}
.hover-cursor:hover{
  cursor: pointer;
}
.auth-lang-toggle{
  @media (max-width: 500px) {
    position: fixed;
    top: 0;
    left: 5px;
    z-index: 100;
    flex-direction: row-reverse;
  }
}
.introjs-tooltipReferenceLayer *{
  font-family: 'cairoChickenApp', serif !important;
}
.btn-guide{
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;;
  align-items: center;
  padding: 0 !important;

}

[dir='rtl']  .avatar-container h5 , .avatar-container small{
  text-align: left !important;

}

[lang='en']  .avatar-container h5 , .avatar-container small{
  text-align: right ;
}
.empty-state-circular-container{
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(47, 56, 75, 0.08);
  //box-shadow: inset 0 0 4px #a9a9a982;
}

[dir='rtl'] .next-btn svg {
  transform: rotate(
                  180deg
  );
}
  [dir='rtl'] .previous-btn svg {
    transform: rotate(-180deg);
  }

  [dir='rtl'] .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
    transform: rotate(360deg);
  }
  [dir = 'rtl'] .introjs-tooltipbuttons{
    display: flex;
    justify-content: space-between;

  }

.react-joyride__spotlight{
  border: 1px solid #0000009c !important;
  box-shadow: 1px 1px 4px 0px #0000007d !important;
  z-index: 1000000 !important;
}
.react-joyride__tooltip button{
  border-radius: 10px !important;
}
.react-joyride__tooltip{
  padding: 0 !important;
}
/*.react-joyride__tooltip div{
  padding: 15px !important;
}*/
.react-joyride__tooltip h4{
  padding: 10px !important;

}
.react-joyride__tooltip :nth-child(2){
  padding: 10px !important;
  border-top: 1px solid #00000014;
  margin-top: 0 !important;
}
.deliveries-guide-icon{
  [dir='ltr'] &{
    margin-left: auto;
  }
  [dir='rtl'] &{
    margin-right: auto;
  }
}
.react-joyride__beacon span{
  border: none !important;
  display: none !important;
}
.left-50{
  left: 50px;
}
.left-0{
  left: 0;
}
.left-20{
  left: 20px;
}
.daily-date-help-btn{
  [dir='rtl'] &{
    float: left;
  }
  [dir='ltr'] &{
    float: right;
  }

}
.daily-data-hep-btn{
  position: absolute;
 right: 0;
  [dir = 'rtl' ] &{
  left: 0 !important;
  }
}
.daily-data-help-btn-container{
  [dir = 'rtl' ] &{
    direction: ltr;
  }
}
.card-footer-wrapper{
  display: flex;
  gap: 20px;
  align-items: center;

  [dir='ltr'] &{
    flex-direction: row-reverse;
  }

}
.iconic-btn{
  display: flex !important;
  gap: 10px;
  align-items: center;
  justify-content: center;
  [dir='rtl'] &{
    flex-direction: row-reverse;
  }
}

.card-summary-wrapper{
  display:flex;
  [dir="ltr"]  &{
    flex-direction: row-reverse;
  }
}
.card-summary-wrapper .image-wrapper{
  [dir="ltr"] &{
    flex-direction: row-reverse;

  }
}
th,td {
  white-space: pre;
}

.farm-cards-row{
  display: grid;

  grid-gap: 14px;
  @media (max-width: 2000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }






.credits{


background: rgba(205, 151, 2, 0.12);
padding: 2px 16px;
width: fit-content;
border-radius: 18px;
}
.avatar-toggle:focus {
  box-shadow: unset !important;
}

.terms-check{
  appearance:none !important;
  background-color: red;
}

  /* background: rebeccapurple;
   padding: 10px;
   display: flex;
   gap: 10px;
   flex-wrap: nowrap;
   justify-content: space-around;*/
}


/*.farm-card-col{
  @media (max-width: 2000px) {
    width: 50%;

  }
  @media (max-width: 500px) {
      width: 100%;
  }

}*/
.card-header{
  font-weight: 700 !important;
}
.c-datatable-filter label{
  width: 76px;

  display: block !important;
  [dir = 'rtl'] &{
    text-align: right ;
  }
  [dir = 'ltr'] &{
    text-align: left ;
  }
}

.delivery-input-section {
  border: 1px solid #cccccc;
  background: #F4F6FA;
  border-radius: 5px;
  padding: 18px 0;
  margin: 39px 0 !important;
}
.bg-gray-50{
  background-color: #f7f7f761 !important;
}
.font-bold{
  font-weight: 500;
}
.p-half{
  padding : 0.8rem !important;
}
.c-datatable-filter  input {
  max-width: 200px !important;
}
.bg-blue-50 {
  background: #F4F6FA;
}
.house-farm-filter-label{
  [dir = 'rtl'] &{
    margin-left: 20px;

  }
  [dir = 'ltr'] &{
    margin-right: 20px;

  }
}
.avatar-toggle:focus{
  box-shadow: unset !important;
}
.bg-success-shader{
  background: rgb(39 169 18 / 14%) !important;
  border: 1px solid #409c441c !important;
  color: #43a047 !important;
  text-align: inherit !important;
}
.bg-success-shader:focus{
  box-shadow: 0 0 0 0.2rem rgb(224 243 221) !important;
}
.bg-danger-shader{

  background: #f283803d !important;
  color: #ef5350 !important;
  border: 1px solid #ef53502e !important;
  text-align: inherit !important;
  font-size: 13px !important;
}
.bg-danger-shader:focus{
  box-shadow: 0 0 0 0.2rem rgb(252 225 225) !important;
}
.report-footer{
  display: flex;
  gap: 5px;
  @media  (max-width: 480px) {
    justify-content: space-between;
    align-items: center;
  }
}
.right-10{
  right: 10px !important;
}
.term-btn{
  padding: 0 !important;
}
.term-btn:hover{
  cursor: pointer;
}
.consent-error-msg{
  @media (max-width: 2000px) {
    padding-right: 30px ;
    padding-left: 30px ;
  }
  @media (max-width: 500px) {
    padding-right: 0 ;
    padding-left: 0;
  }

}

.new_daily_data_btn{
  position: fixed;
  z-index: 10000000;
  background-color: #1f9980 !important;
  bottom: 55px;
  $btnDirection:70px;
  @media (max-width: 2000px) {
    [dir = 'rtl'] &{
      left:$btnDirection;
    }
    [dir = 'ltr'] &{
      right: $btnDirection;
    }
  }
  @media (max-width: 500px) {
    [dir = 'rtl'] &{
      left: 30px;
    }
    [dir = 'ltr'] &{
      right: 30px;
    }
  }
}


.rounded-with-outline-dashboard{

  border-radius: 100px !important;
  position: fixed;
  z-index: 100000;
  border: 2px solid white !important;
  box-shadow: 0 0 0 0.2rem #F9B115 ;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;


  @media (max-width: 2000px) {
    width: 30px;
    height: 30px;

    $disktopBottonSpace : 55px ;

    [dir='ltr'] &{
      right: 20px;
      bottom: $disktopBottonSpace;
    }
    [dir='rtl'] &{
      left: 30px;
      bottom: $disktopBottonSpace;
    }
  }
  @media (max-width: 426px) {
    $mobileBottomSpace:55px;
    $mobileSideSpace:30px;
    width: 30px;
    height: 30px;

    [dir='ltr'] &{
      right: $mobileSideSpace;
      bottom: $mobileBottomSpace;
    }
    [dir='rtl'] &{
      left: $mobileSideSpace;
      bottom: $mobileBottomSpace
    }

  }

  &:after{
    border: 1px solid red !important;
  }
}

.rounded-with-outline{

  border-radius: 100px !important;
  position: fixed;
  z-index: 100000;
  border: 2px solid white !important;
  box-shadow: 0 0 0 0.2rem #F9B115 ;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;


  @media (max-width: 2000px) {
    width: 30px;
    height: 30px;

    $disktopBottonSpace : 55px ;

    [dir='ltr'] &{
      right: 20px;
      bottom: $disktopBottonSpace;
    }
    [dir='rtl'] &{
      left: 30px;
      bottom: $disktopBottonSpace;
    }

  }
  @media (max-width: 426px) {

    $mobileBottomSpace:110px;
    $mobileSideSpace:30px;
    width: 30px;
    height: 30px;

    [dir='ltr'] &{
      right: $mobileSideSpace;
      bottom: $mobileBottomSpace;
    }
    [dir='rtl'] &{
      left: $mobileSideSpace;
      bottom: $mobileBottomSpace
    }

  }

  &:after{
    border: 1px solid red !important;
  }
}

.danger-container{
  border:1px solid #d2d2d2;
  background-color: #fafafa;
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
}
.lg-hide{
  @media (max-width: 2000px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: block;
  }
}
@import "node_modules/@coreui/coreui/scss/coreui";