
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker__input-container button {
  border: none;
  color: #293D52;
  border: 1px solid rgba(185,190,199,.5);
  box-shadow: 0 0 #0000,0 0 #0000,0 0 #0000,0 0 #0000,0 1px 5px 0 rgba(0,0,0,0.1) ;
  background-color: white;
  border-radius: 5px;
  padding: 0.3rem 0.7rem;
}

.react-datepicker__input-container button:focus{
  border: 1px solid rgba(185,190,199,.5);
  background-color: #f8f8f8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td{
 display: table-cell;
  vertical-align: baseline !important;

}

.add-btn{
  background: #26B99A;
  color: white !important;
  padding: 7px;
  border-radius: 11px;
  text-decoration: none !important;
}

.pagination{
  margin-top: 20px;
}

.react-datepicker__input-container button:focus {
  border: none;

}

.react-datepicker__input-container button {

  color: #293D52;
  border: 1px solid rgba(185,190,199,.5);
  background-color: white;
  border-radius: 5px;
  padding: 0.3rem 0.7rem;
}
.react-datepicker__input-container button:focus{
  outline: none;
  border: 1px solid rgba(185,190,199,.5);
  background-color: #f8f8f8;
}
.c-sidebar {
  color: #fff;
  background: #293D52;
}
button:focus{
  outline: none;
}


  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #26B99A;
  }

.page-link {
  color: #213142;
}
.page-item.active .page-link {

  background-color: #26b99a;
  border-color: #26b99a;
}
.c-sidebar .c-sidebar-minimizer {
  background-color: rgb(33 49 70);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  left: -80px !important;
}

/*scroller*/
.ps__thumb-y {
  background-color: #00000080 !important;
}
.ps__rail-y{
  z-index: 1 !important;
}
.chicken-app-tooltip{

  position: absolute;
  font-size: 10px;
  color: white;
  top: -10px;
  left: -20px;
  background-color: rgba(0, 0, 0, 0.38);
  padding:  1px 6px;
  border-radius: 5px;
  visibility: hidden;

;
}
.chicken-app-language-btn {
  color: #293D52;
  border: 1px solid rgba(185,190,199,.5);
  background-color: white;
  border-radius: 5px;
  padding: 0.3rem 0.7rem;

}

.text-gray-100{
  color:#94A3B8
}


.chicken-app-language-btn:hover {
  background-color: #f6f6f6;
}
.chicken-app-language-btn:focus{
  outline: none;
}


.lang-dropdown-menu-new [dir="rtl"]{
  background-color: red;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(6px, 10px) !important;
}

.lang-dropdown-menu-new{
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(6px, 37px) !important;
}
.lang-dropdown-menu{
  bottom: 60px;

  padding: 5px;background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.18);

}
.lang-dropdown-menu span{

  padding: 5px;
  border-radius: 5px;
  text-decoration: unset;

}.lang-dropdown-menu span:hover{text-decoration: unset;


  padding: 5px;
  border-radius: 5px;
  background-color: #f9f9f9;
}
 .flag{
   border-radius: 5px;
   border: 1px solid black;
   width: 40px ;
   background-size: cover;
   background-position: center;
   height: auto;
 }


.offline-header{
  background-color: rgba(255, 0, 0, 0.19);
}.online-header{
  background-color: rgba(46, 184, 92, 0.15);
}
.offline-dot {
  width: 10px;
  height: 10px;
  border-radius: 10000px;
  background-color: red;
  margin-right: 5px;

  position: relative;

}.online-dot {
  width: 10px;
  height: 10px;
  border-radius: 10000px;
  background-color: #2EB85C;
  margin-right: 5px;

  position: relative;

}

.text-red{
  color: red ;
}

@media (max-width: 991.98px){
  .d-md-down-none {
    display: flex !important;
  }
}

@media (max-width: 991.98px){
  .d-md-down-none {
    display: none !important;
  }
}


@media (max-width: 991.98px){
  .d-md-down-none {
    display: flex !important;

  }
}

.pagination.justify-content-start{
  margin-bottom: 0px;
}
.chicken-app-tooltip.myclass{
  visibility: visible;
}


@media (min-width: 450px) {
  .collapsible-container{
    display: flex !important;
  }
  .mobile-collapsable-container{
    display: none !important;
  }

  .daily-data-btn{
    position: fixed;
    z-index: 1000000;
    bottom: 30px;
    right: 30px;
    border: 4px solid white;
    padding: 10px;
    border-radius: 20px;
  }
}

[dir="rtl"] .daily-data-btn{
  position: fixed;
  z-index: 1000000;
  bottom: 30px;
  left: 10px;
  border: 4px solid white;
  padding: 10px;
  border-radius: 20px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {

  left: 50% !important;
}


 .table .btn.btn-outline-danger.btn-sm.btn-square {
  margin-right: 10px;
}
.app-header{
  box-shadow: -1px 1px 3px 0px #5566557a !important;
}
[dir="rtl"] .react-multi-carousel-list{
  direction: ltr;
}
.summary-card-name{
  font-size: 5px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */
}

